import React, { useState, useEffect } from "react"
import dateFormat from 'dateformat';
import { Form, Row } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import './assets/styles/_index.scss'
import { postFormData } from "./api/Api"
import { phoneNumberValidation, uniqueID, Mail_Function } from "../common/utils"
import { CustomLinks } from "../common/menuUtils"

import $ from "jquery"

function CallbackForm(props) {

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();
  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectError, setSelectError] = useState(false);

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  const fields = ([
      {
        element: "config",
        formname: "Request Call back",
        form_type: "request call back",
        error_text: "Highlighted fields are required.",
        success_text: "Thank you, a member of our team will be in contact soon.",
        email_temp_user: "request_callback_user",
        email_temp_admin: "request_callback_admin",
        email_server_func: "request call back",
        event_tracking: "request call back",
        page_url: props.location.href,
        email_subject_user: "Request Call Back",
        email_subject_admin: "Request Call Back"
      },      
      {
        grpmd: "12",
        label: "Full NAME",
        placeholder: "",
        name: "name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[a-zA-Z0-9._-]+(\\s+[a-zA-Z0-9._-]+)*$",
        labelClass: "annual-txt",
        errorMsg: "Alphanumeric and special characters (-_.) are only allowed."
      },
      {
        grpmd: "12",
        label: "TELEPHONE NUMBER",
        placeholder: "",
        name: "telephone",
        type: "text",
        element: "input",
        required: true,
        patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "annual-txt",
        errorMsg: "Enter a valid phone number, Ex: 01234 567 890."
      },
      {
        grpmd: "12",
        label: "Email Address",
        placeholder: "",
        name: "email",
        type: "email",
        element: "input",
        required: true,
        patternchk:"[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
        labelClass: "annual-txt",
        errorMsg: "Enter a valid email id, Ex: james123@gmail.com, JAMES123@GMAIL.COM."
      },
      {
        grpmd: "6",
        label: "Preferred Date",
        placeholder: "",
        name: "preferred_date",
        type: "valuation_date",
        element: "input",
        required: true,
        // patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "annual-txt",
        class:"date_field"
      },
      {
        grpmd: "6",
        label: "Preferred Time",
        placeholder: "",
        name: "preferred_time",
        type: "valuation_time",
        element: "input",
        required: true,
        // patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "annual-txt",
        class:"date_field"
      },
      {
        grpmd: "12",
        label: "DETAILS OF YOUR ENQUIRY",
        placeholder: "",
        name: "message",
        element: "textarea",
        class: "",
        required: true,
        rows:"4",
        labelClass: "annual-txt",
        errorMsg: "Enter the enquiry message."
      },      
      {
        text: 'A member of our team will contact you during office hours between 09:30 and 18:00 Monday to Friday',
        element: "html",
        class: "property-intro-text mb-4"
      },
      {
        grpmd: "12",
        name: "SUBMIT",
        type:"submit",
        element: "button",
        value: "Request A call back",
        class: "btn btn-green",
        labelClass: "",
        customClass:"custom-button-wrapper"
      },
      
      {
        element: "captcha",
        class: "py-2",
        captchaRef: recaptchaRef
      },
    ]);

  const handlechange = event => {
    // remove initial empty spaces
    //console.log("check_event",event);
    if(event.type === "select"){
      //event.value = event.value.trimStart()
      setSelectedOptions(event);
    } else{
      if(event.target.name === "telephone"){
        var valid_phone = phoneNumberValidation(event.target.value);
        if(valid_phone){
          event.target.value = valid_phone;
        } else{
          event.target.value = '';
        }
      } else{
        event.target.value = event.target.value.trimStart()
      }
    }

  }

  useEffect(() => {

    //console.log("token", token);
    
    if (token !== '') {

    const processFromData = async () => {

      let formData = new FormData();

      formData.append('data', JSON.stringify(formvalues));

      postFormData(formData).then(async apiRes => {

          //console.log("formvalues", formvalues);

          window.grecaptcha.reset();
          await Mail_Function(formvalues);

          // Campaign Monitor
          if(formvalues.signup_newsletter === "yes"){
            //await CampaignMonitorDirect(formvalues);
          }
          // Campaign Monitor

      });

      formvalues['g-recaptcha-response'] = token;


      // tracking event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmit',
        'formType': 'form-'+fields[0].event_tracking,
        'formId': 'form-'+fields[0].event_tracking,
        'formName': fields[0].formname,
        'formLabel': fields[0].formname
      });

      setShowerror(false);
      setThankyou(true);
      setTimeout(() => {
        setThankyou(false)
      }, 3000);

      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }

      
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    //setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    //console.log("event =>", form);

    var form_obj_arr = Object.entries(form);

    //console.log("form_obj_arr =>", form_obj_arr.filter(item => item[1].required === true));

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);

      if(selectedOptions?.value){
        setSelectError(false);
      } else{
        setSelectError(true);
      }
      

      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });      
    }
    else {

      event.preventDefault();
      const formsdata = (event.target);

      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = formsdata[key].type === "checkbox" ? (formsdata[key].checked === true ? 'yes' : '') : formsdata[key].value
      ))

      //json['name'] = json.first_name+' '+json.last_name;
      var date_time = dateFormat(startDate, "mmm dd, yyyy")+" "+dateFormat(startTime, "hh:MM TT");

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['email_subject_user'] = fields[0].email_subject_user;
      json['email_subject_admin'] = fields[0].email_subject_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;
      json['date_time'] = date_time;
      json['extra'] = {"preferred_date":dateFormat(startDate, "yyyy-dd-mm"), "preferred_time":dateFormat(startTime, "HH:MM"), "page_url": props.location.href};

      //console.log("formsdata_json", json);

      setFormvalues(json);
      setToken("test");
      
      recaptchaRef.current.execute();

      setValidated(false);
      setSelectError(false);
      setSelectedOptions({});
      
      // reset form
      const form = event.target
      form.reset();
      setStartDate();
      setStartTime();
      
    }
  };

  //console.log("selectedOptions", selectedOptions.length);

  return (
    <div className="form">
    
    {showerror && <div className="alert alert-danger">
      {fields[0].error_text}
    </div>}
    <div ref={myRef}></div>
    
    {showthankyou && <div className="alert alert-success">
      {fields[0].success_text}
    </div>}

    
      <Form name={fields[0].formname} className={props.classNames} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">
        
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="form_label" value={props.formLabel} />
        <input type="hidden" name="to_email_id" value={""} />
        <input type="hidden" name="admin_email" value={"Yes"} />
        
        <input type="hidden" name="bot-field" />
        
        <Row className="property-form-wrapper">
        {fields.map((field, index) => {
            if ( "input" === field.element ) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  errorMsg={field.errorMsg}
                  startDate={field.type === 'valuation_date' ? startDate : ''}
                  setStartDate={field.type === 'valuation_date' ? setStartDate : ''}
                  startTime={field.type === 'valuation_time' ? startTime : ''}
                  setStartTime={field.type === 'valuation_time' ? setStartTime : ''}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}                  
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                  selectedValue={selectedOptions}
                  errorMsg={field.errorMsg}
                  isError={selectError ? true : '' }
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <>
                
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  errorMsg={field.errorMsg}
                />
                </>
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <>
                
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                  grpmd={field.grpmd}
                  customClass={field.customClass}
                />
                
                </>
              );
            }
          })
        }
        </Row>
      </Form>

      
    </div>
  );
}


const ContactFormPage = (props) => (
  <CallbackForm {...props} />
  
)

export default ContactFormPage