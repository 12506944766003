import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"

// Components

import CallbackForm from "../components/forms/request-callback-form";
import BookViewingForm from "../components/forms/book-a-viewing";

const ModalForm = ( props ) => {

    return (
    <>
    <Modal
    show={props.isOpen}
    size="lg"
    onHide={() => props.setIsOpen(false)}
    backdrop="static"
    keyboard={false}
    className="details-booking-modal sml_wdth"
>
    {props.form_type === "request_call_back" ? 
        <>
            <Modal.Header closeButton>
                <Modal.Title>Request call back</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="property-intro-text mb-4">Quick and easy. Send us a message and one of the team will give you a call back.</div>
                <CallbackForm location={props.location}/>
            </Modal.Body>
        </> : 
        <>
            <Modal.Header closeButton>
                <Modal.Title>Enquire about this property</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="property-intro-text mb-4">For more information or to book a viewing, please leave a few details below and a member of our team will be in contact soon.</div>

                <BookViewingForm location={props.location} propertyData={props?.propertyData}/>
            </Modal.Body>
        </>
    }
    </Modal>
    </> 
    )
}

export default ModalForm

