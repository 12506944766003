import React, { useState, useEffect } from "react"
import dateFormat from 'dateformat';
import { Form, Row } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import './assets/styles/_index.scss'
import { postFormData } from "./api/Api"
import { phoneNumberValidation, uniqueID, Mail_Function } from "../common/utils"

import $ from "jquery"

function BookViewing(props) {

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();
  const [formvalues, setFormvalues] = useState("");

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectError, setSelectError] = useState(false);

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();


  const fields = ([
      {
        element: "config",
        formname: `Book a Viewing - ${props?.propertyData?.department}`,
        form_type: `book_a_viewing_${props?.propertyData?.department}`,
        error_text: "Highlighted fields are required.",
        success_text: "Thank you, a member of our team will be in contact soon.",
        email_temp_user: "book_a_viewing_user",
        email_temp_admin: "book_a_viewing_admin",
        email_server_func: "book_a_viewing",
        event_tracking: "book_a_viewing",
        page_url: props.location?.pathname,
        email_subject_user: `Book a Viewing`,
        email_subject_admin: `Book a Viewing`,
        //admin_email: true
      },      
      {
        grpmd: "12",
        label: "Name",
        placeholder: "",
        name: "name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[a-zA-Z0-9._-]+(\\s+[a-zA-Z0-9._-]+)*$",
        labelClass: "annual-txt",
        errorMsg: "Alphanumeric and special characters (-_.) are only allowed."
      },
      {
        grpmd: "12",
        label: "Email Address",
        placeholder: "",
        name: "email",
        type: "email",
        element: "input",
        required: true,
        patternchk:"[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
        labelClass: "annual-txt",
        errorMsg: "Enter a valid email id, Ex: james123@gmail.com, JAMES123@GMAIL.COM."
      },
      {
        grpmd: "12",
        label: "Telephone Number",
        placeholder: "",
        name: "telephone",
        type: "text",
        element: "input",
        required: true,
        patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "annual-txt",
        errorMsg: "Enter a valid phone number, Ex: 01234 567 890."
      },
      {
        grpmd: "6",
        label: "Preferred Date",
        placeholder: "",
        name: "preferred_date",
        type: "valuation_date",
        element: "input",
        required: true,
        // patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "annual-txt",
        class:"date_field"
      },
      {
        grpmd: "6",
        label: "Preferred Time",
        placeholder: "",
        name: "preferred_time",
        type: "valuation_time",
        element: "input",
        required: true,
        // patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "annual-txt",
        class:"date_field"
      },
      {
        grpmd: "12",
        label: "MESSAGE",
        placeholder: "",
        name: "message",
        element: "textarea",
        class: "mb-4",
        required: true,
        rows:"4",
        labelClass: "annual-txt",
        errorMsg: "Enter the enquiry message."
      },
      {
        grpmd: "12",
        name: "SUBMIT",
        type:"submit",
        element: "button",
        value: "Submit Enquiry",
        class: "btn btn-green",
        labelClass: "",
        customClass:"custom-button-wrapper"
      },
      {
        element: "captcha",
        class: "py-2",
        captchaRef: recaptchaRef
      },
    ]);

  const handlechange = event => {
    // remove initial empty spaces
    //console.log("select_event",event);
    if(event.type === "select"){
      //event.value = event.value.trimStart()
      setSelectedOptions(event);
    } else{
      if(event.target.name === "telephone"){
        var valid_phone = phoneNumberValidation(event.target.value);
        if(valid_phone){
          event.target.value = valid_phone;
        } else{
          event.target.value = '';
        }
      } else{
        event.target.value = event.target.value.trimStart()
      }
    }

  }

  useEffect(() => {

    //console.log("token", token);
    
    if (token !== '') {

    const processFromData = async () => {

      let formData = new FormData();

      formData.append('data', JSON.stringify(formvalues));

      postFormData(formData).then(async apiRes => {
          window.grecaptcha.reset();
          await Mail_Function(formvalues);
      });

      formvalues['g-recaptcha-response'] = token;


      // tracking event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmit',
        //'formType': 'form-'+fields[0].event_tracking,
        'formType': formvalues.enquirytype,
        'formId': 'form-'+fields[0].event_tracking,
        'formName': fields[0].formname,
        'formLabel': fields[0].formname
      });

      setShowerror(false);
      setThankyou(true);
      setTimeout(() => {
        setThankyou(false)
      }, 3000);

      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    //setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    var form_obj_arr = Object.entries(form);

    //console.log("form_obj_arr =>", form_obj_arr.filter(item => item[1].required === true));

    form_obj_arr.filter(item => item[1].required === true).map(item =>{
      if(item[1].validationMessage !== ""){
        $(".error_msg."+item[1].name).addClass('show');
      } else{
        $(".error_msg."+item[1].name).removeClass('show');
      }
    })
    
    if(selectedOptions.length === 0){
      setSelectError(true)
      $(".error_msg.department").addClass('show');
    } else{
      setSelectError(false)
      $(".error_msg.department").removeClass('show');
    }

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))
      
      var date_time = dateFormat(startDate, "mmm dd, yyyy")+" "+dateFormat(startTime, "hh:MM TT");

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['email_subject_user'] = fields[0].email_subject_user;
      json['email_subject_admin'] = fields[0].email_subject_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;
      json['date_time'] = date_time;
      json['extra'] = {"property_id": json.property_id, "department" : json.department, "property_url" : json.property_url, "property_address" : json.property_address, "property_img" : json.property_img, "preferred_date":dateFormat(startDate, "yyyy-dd-mm"), "preferred_time":dateFormat(startTime, "HH:MM")};

      //console.log("formsdata_json", json);

      setFormvalues(json);
      setToken("test");

      recaptchaRef.current.execute();

      setValidated(false);

      setSelectError(false);
      setSelectedOptions([]);

      // reset form
      const form = event.target
      form.reset();
      setStartDate();
      setStartTime();
    }
  };

  return (
    <div className="form">
    
    <div ref={myRef}>

      {showerror && <div>
        {/* {fields[0].error_text} */}
      </div>}
      
      {showthankyou && <div className="alert alert-success">
        {fields[0].success_text}
      </div>}

    </div>


      <Form name={fields[0].formname} className={props.classNames} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit}>

        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="form_label" value={props.formLabel} />

        <input type="hidden" name="department" value={props?.propertyData?.department} />
        <input type="hidden" name="property_url" value={props.location?.pathname ? process.env.GATSBY_SITE_URL+props.location.pathname : ''} />
        <input type="hidden" name="property_address" value={props.propertyData?.display_address ? props.propertyData.display_address : ''} />
        <input type="hidden" name="property_img" value={props.propertyData?.images && props.propertyData.images.length > 0 ? props.propertyData.images[0].srcUrl : ''} />

        <input type="hidden" name="bot-field" />

        <input type="hidden" name="to_email_id" value={""} />

        <input type="hidden" name="admin_email" value={"Yes"} />
        
        <Row className="property-form-wrapper">
        {fields.map((field, index) => {
            if ( "input" === field.element ) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  errorMsg={field.errorMsg}
                  startDate={field.type === 'valuation_date' ? startDate : ''}
                  setStartDate={field.type === 'valuation_date' ? setStartDate : ''}
                  startTime={field.type === 'valuation_time' ? startTime : ''}
                  setStartTime={field.type === 'valuation_time' ? setStartTime : ''}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                  selectedValue={selectedOptions}
                  errorMsg={field.errorMsg}
                  isError={selectError ? true : '' }
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <>
                  <TextAreaField
                    name={field.name}
                    grpmd={field.grpmd}
                    label={field.label}
                    ref={field.ref}
                    rows={field.rows}
                    fieldClass={field.class}
                    labelClass={field.labelClass}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.element}~${index}`}
                    handlechange={handlechange}
                    errorMsg={field.errorMsg}
                  />
                </>
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <>
                
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                  grpmd={field.grpmd}
                  customClass={field.customClass}
                />
                
                </>
              );
            }
          })
        }
        </Row>

       
        
      </Form>
    </div>
  );
}


const BookViewingFormPage = (props) => (
  <BookViewing {...props} />
  
)

export default BookViewingFormPage